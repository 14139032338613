@import '~styles/breakpoints';

.image-modal-background {
  width: 100vw;
  height: 100vh;
  background: var(--standard-gradient);

  @media (min-width: $bp-from-desktop) {
    width: 90vw;
    height: 90vh;
    border-radius: 15px;
    overflow: hidden;
  }
}

.image-modal {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  display: grid;
  align-items: center;

  img {
    width: 100%;
  }
}

.modal-close-icon {
  @media(min-width: $bp-from-desktop) {
    position: relative;
    height: 50px;
    margin: -50px;
  }
}

.image-modal {
  #close-icon {
    width: 60px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    .close-a {
      fill: var(--main-light);
    }

    .close-b {
      fill: url(#close-a);
    }
  }

  @media (min-width: $bp-from-desktop) {
    #close-icon {
      width: 40px;
      position: absolute;
      right: 30px;
      top: 30px;
    }
  }
}

.modal-arrow {
  opacity: 0.5 !important;
}
