.contact-label-wrapper {
  display: flex;
  align-items: center;
}

.contact-label-icon {
  width: 24px;
  height: 24px;
  margin: 10px;
}

.contact-label {
  overflow: auto;
}