@import '~styles/breakpoints';

.portfolio-select-wrapper {
  height: 100vh;
  overflow: auto;
  display: grid;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;

  a {
    text-align: center;
  }

  @media (min-width: $bp-from-tablet) {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.portfolio-menu-card {
  box-shadow: 5px 5px 10px 0 var(--faded-dark);
  margin: 25px auto;
  width: 80%;
  display: grid;
  justify-items: stretch;
  text-align: center;
  border-radius: 15px;

  h5 {
    font-size: 0.9rem;
    color: var(--gradient-end);
  }
}

.portfolio-menu-card-icon-wrapper {
  transition: transform 0.25s;
  &:hover {
    cursor: pointer;
    transform: translateY(-10px) scale(1.05);
  }
  svg {
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    fill: var(--gradient-end);
  }
}