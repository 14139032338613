@import '~styles/breakpoints';

.portfolio-view-wrapper {
  display: grid;

  &.narrow {
    @media (min-width: $bp-from-tablet) {
      grid-template-areas: initial;
      grid-template-columns: minmax(150px, 1fr) 8fr;
    }
  }

  @media (min-width: $bp-from-tablet) {
    overflow: hidden;
  }
}

.portfolio-items-view-wrapper {
  padding-top: 75px;
  height: 100vh;
  max-width: 100%;

  @media (min-width: $bp-from-tablet) {
    padding-left: 50px;
    overflow: auto;
  }

  h1 {
    text-align: center;
    background: linear-gradient(90deg, var(--gradient-start), var(--gradient-end));
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.portfolio-items-view-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
  grid-gap: 20px;
  justify-items: stretch;
  padding: 10px;

  @media (max-width: $bp-to-tablet) {
    padding-bottom: 150px;
  }
}

.portfolio-project-card {
  border-radius: 15px;
  background: linear-gradient(235deg, var(--gradient-start), var(--gradient-end));
  color: var(--main-light);
  height: 250px;
  display: grid;
  grid-template-rows: 8fr 1fr;
  transition: transform 0.25s;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  h4 {
    margin: 10px;
    font-size: 120%;
  }
}

.portfolio-thumbnail-wrapper {
  background-position: center;
  background-size: cover;
  overflow: hidden;

  img {
    width: 100%;
  }
}