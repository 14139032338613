@import '~styles/breakpoints';

.about {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));

  @media (min-width: $bp-from-desktop) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

.about-image-wrapper {
  border-radius: 100vw;
  background: var(--main-light);
  width: 60vw;
  height: 60vw;
  margin: 20px auto;
  display: grid;
  align-items: center;
  justify-items: center;

  @media (min-width: $bp-from-tablet) {
    width: 30vw;
    height: 30vw;
  }

  @media (min-width: $bp-from-desktop) {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: 20px;
  }
}

.about-image {
  width: 95%;
  height: 95%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(./img/about.jpg);
  border-radius: 100vw;
}

.about-text {
  color: var(--main-light);
  padding: 20px;
  font-size: 1.2rem;

  @media (min-width: $bp-from-desktop) {
    width: 50%;
    margin-right: auto;
  }
}