@import '~styles/breakpoints';

.side-menu-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: grid;
  text-align: center;
  z-index: 100;
  grid-template-rows: 30px 1fr;
  grid-template-areas:
  "wave"
  "list";

  .side-menu-wave {
    pointer-events: none;
    width: 100%;
    background: linear-gradient(270deg, var(--gradient-start), var(--gradient-end));
    position: relative;
    bottom: 0px;
    clip-path: url(#side-menu-horizontal-wave-mask);
    grid-area: wave;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h4 {
    margin: 0;
    font-size: 0.75rem;
    transition: opacity 0.25s, visibility 0.25s, transform 0.25s;
  }

  a {
    color: var(--half-transparent-light);
    border-radius: 1000px;
    padding: 10px 20px;
    margin: 15px 0;
    background-color: rgba(0,0,0,0.25);
    transition: background-color 0.25s, color 0.25s;


    & li {
      transition: transform 0.25s;
      transform: scale(0.85);
    }

    &.active {
      background-color: var(--main-light);
      color: var(--gradient-start);
      cursor: default;

      & li {
        transform: scale(1);
      }
    }
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 20px;
    grid-area: list;
    background: linear-gradient(270deg, var(--gradient-start), var(--gradient-end));


    li {
      display: inline-block;
      text-transform: uppercase;
      position: relative;
      top: -1px;
    }
  }

  @media (min-width: $bp-from-tablet) {
    position: relative;
    left: 0;
    height: 100vh;
    width: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 30px;
    grid-template-areas: "list wave";
    background: linear-gradient(180deg, var(--gradient-start), var(--gradient-end));

    h4 {
      opacity: 0;
      margin-top: 10px;
      visibility: hidden;
      transform: translateY(-20px);
    }

    .side-menu-wave {
      width: 30px;
      max-height: 100vh;
      position: absolute;
      top: 0;
      background: linear-gradient(180deg, var(--gradient-start), var(--gradient-end));
      clip-path: url(#side-menu-horizontal-wave-mask);
      grid-area: wave;

      svg {
        width: 100%;
        position: absolute;
        right: -2px;
        path {
          fill: var(--main-light);
        }
      }
    }

    a {
      background-color: transparent;
      margin: 0;

      &.active {
        background-color: transparent;
        color: var(--main-light);
        transform: translateY(-5px);

        .side-menu-icon-wrapper {
          opacity: 1;
        }

        h4 {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }

    ul {
      margin-top: 80px;
      flex-flow: column;
      justify-content: start;
      overflow-x: hidden;
      background: transparent;
    }
  }
}

.side-menu-icon-wrapper {
  display: none;
  margin: 0 auto;
  background-color: var(--main-light);
  border-radius: 1000px;
  padding: 12px;
  width: 70px;
  height: 70px;
  opacity: 0.3;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }

  @media (min-width: $bp-from-tablet) {
    display: grid;
    align-items: center;
  }

  svg {
    fill: var(--gradient-end);
  }
}