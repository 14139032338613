@import '~styles/breakpoints';

a {
  color: var(--main-light);
}

.view-wrapper.resume {
  @media (min-width: 1500px) {
    max-width: 90vw;
    margin: 0 auto;
    margin-bottom: 150px;
  }
}

header#name {
  color: var(--main-light);
  text-align: center;
  padding-top: 20px;
  position: absolute;
  top: 80px;
  width: 100%;

  h1 {
    font-size: 2em;
    margin: 0;
    font-weight: 600;
  }

  h2 {
    font-size: 0.8em;
    font-weight: 600;
  }

  @media (min-width: $bp-from-desktop) {
    width: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    h1 {
      font-size: 3em;
    }

    h2 {
      font-size: 1.2em;
    }
  }
}

.grid-contact-wrapper {
  padding: 15px;
  padding-top: 185px;

  @media (min-width: $bp-from-desktop) {
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
  }
}

.resume-top {
  background: linear-gradient(220deg, var(--gradient-start), var(--gradient-end));

  @media (min-width: $bp-from-desktop) {
    display: grid;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.resume-top ul {
  color: var(--main-light);

  @media (min-width: $bp-from-desktop) {
    margin: 0 5vw;
  }
}

.resume-photo-wrapper {
  border-radius: 1000px;
  background-color: var(--main-light);
  width: 50vw;
  height: 50vw;
  position: relative;
  margin: 0 auto;
  margin-bottom: -50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $bp-from-desktop) {
    width: 200px;
    height: 200px;
    position: relative;
    top: -80px;
    margin-bottom: -130px;
  }

  .resume-photo {
    background: url('./img/resume-photo.jpg');
    border-radius: 1000px;
    background-size: 175%;
    background-position: center;
    width: 90%;
    height: 90%;
    z-index: 1;
  }
}

svg#resume-top {
  position: relative;
  bottom: -5px;
}

.resume-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  background: linear-gradient(225deg, var(--gradient-start), var(--gradient-end));
  box-shadow: 0 0 5px 1px var(--color-shadow);
}

section.resume-section {
  &#experience {
    padding-top: 50px;
  }

  header {
    display: grid;
    align-items: center;
    grid-template-columns: 15vw 1fr;

    @media (min-width: $bp-from-tablet) {
      grid-template-columns: 80px 1fr;
    }
  }

  .header-wrapper {
    margin: 10px;
    width: 15vw;
    height: 15vw;

    max-width: 80px;
    max-height: 80px;

    img {
      width: 60%;
    }

    img#experience-icon {
      position: relative;
      top: -2px;
    }
  }

  .big-wrapper {
    width: 20vw;
    height: 20vw;
    margin: 10px;

    max-width: 100px;
    max-height: 100px;

    img {
      width: 60%;
    }

    #followup {
      position: relative;
      width: 50%;
      top: -2px;
      right: -1px;
    }

    #backend-icon {
      width: 50%;
    }

    #frontend-icon {
      width: 70%;
    }
  }

  h1, h6 {
    background: linear-gradient(180deg, var(--gradient-start), var(--gradient-end));
    background-size: 300% 200%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  h1 {
    position: relative;
    margin-left: 20px;
    top: -6px;

    font-size: 1.75em;

    @media (max-width: 320px) {
      font-size: 1.5em;
    }

    @media (min-width: $bp-from-mobile) {
      font-size: 2em;
    }

    @media (min-width: $bp-from-tablet) {
      font-size: 2.5em;
    }

    &::after {
      content: '';
      display: block;
      background: linear-gradient(-45deg, var(--gradient-start), var(--gradient-end));
      background-size: 300% 100%;
      background-position-x: right;
      width: 94%;
      height: 3px;
      border-radius: 50px;
      position: absolute;
      top: 1.9ch;
      left: 2px;
    }
  }

  .resume-section-content {
    margin: 15px;

    @media (min-width: $bp-from-tablet) {
      margin: 50px;
    }

    &#experience {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 65px 1fr;

      & > .label-wrapper {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr 3fr 1fr;

        @media (min-width: $bp-from-tablet) {
          grid-template-columns: 120px 6fr 1fr;
        }

        @media (min-width: $bp-from-desktop) {
          margin: 0 2%;
          width: 40%;
        }

        border-radius: 1000px;

        transition: background-color 0.25s;

        &:hover {
          background-color: var(--secondary-light);
          cursor: pointer;

          & > svg {
            animation: arrow-right 0.5s;
          }
        }
      }
    }
  }

  h4, h5 {
    margin: 0;
    color: var(--secondary-dark);
  }

  h4 {
    font-weight: 600;
    font-size: 0.9rem;
  }

  h5 {
    font-weight: normal;
    font-size: 0.8rem;
  }

  svg#arrow-svg {
    height: 45px;
    fill: var(--standard-gradient);
    margin: 0 auto;
    transition: transform 0.25s;
  }

  .tech-stack-item {
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
    transition: background-color 0.25s;
    position: relative;

    &:hover {
      cursor: pointer;
      background-color: var(--secondary-light);

      &:last-of-type {
        cursor: default;
        background-color: var(--main-light);
      }
    }

    & .big-wrapper {
      margin: 20px auto;
      width: 40vw;
      height: 40vw;
      position: relative;

      @media(max-width: $bp-from-tablet) {
        &::after {
          content: url('../../components/svg/icons/ZoomInIcon.svg');
          display: block;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin: 20px auto;
          position: absolute;
          bottom: -30%;
          animation: blink 1s infinite;
          right: -5%;
          border: 2px solid var(--main-light);
          background: linear-gradient(225deg, var(--gradient-start), var(--gradient-end));
        }

        &:last-of-type::after {
          display: none;
        }
      }
    }

    @keyframes blink {
      0% {
        transform: scale(1);
        filter: brightness(1);
      }
      50% {
        transform: scale(1.25);
        filter: brightness(1.25);
      }
      100% {
        transform: scale(1);
        filter: brightness(1);
      }
    }

    @media (min-width: $bp-from-tablet) {
      grid-template-columns: 140px 1fr;
    }

    h6 {
      margin: 0;
      font-size: 0.8rem;
    }

    li {
      color: var(--secondary-dark);
      font-size: 0.9rem;
      margin: 10px 0;

      b {
        font-weight: 600;
      }
    }

    &#tools-stack {
      grid-template-columns: 1fr;
      @media (min-width: $bp-from-tablet){
        padding-left: 160px;
      }

      & #tools-span {
        display: block;
        font-weight: 600;
        color: var(--secondary-dark);
        font-size: 0.9rem;
      }
    }
  }

  ul#skills-list {
    margin: 20px 30px;

    @media(min-width: $bp-from-tablet) {
      margin: 20px 50px;
    }
    li {
      color: var(--secondary-dark);
      margin: 10px 0;
      font-size: 0.9rem;

      b {
        font-weight: 600;
      }

      &::before {
        content: '> ';
        position: relative;
        right: 15px;
        margin-right: -8px;
        display: inline-block;
        font-weight: bold;
        background: linear-gradient(180deg, var(--gradient-start), var(--gradient-end));
        background-size: 300% 200%;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        transform: scaleY(1.6);
      }
    }
  }
}

@keyframes arrow-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0);
  }
}