@import '~styles/breakpoints';

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: var(--half-transparent-dark);
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s, visibility 0.25s;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.modal {
  width: 100%;
  height: 100vh;
  background-color: var(--main-light);
  position: relative;

  @media (min-width: $bp-from-desktop) {
    width: 60vw;
    height: 80vh;
    border-radius: 15px;
    overflow: hidden;
  }
}

.modal-header {
  display: grid;
  align-items: center;
  grid-template-columns: 140px 6fr 80px;
  background: linear-gradient(270deg, var(--gradient-start), var(--gradient-end));
  height: 20vh;
  color: var(--main-light);

  & h1 {
    text-transform: uppercase;
  }
}

#modal-wave-svg {
  position: absolute;
  top: calc(20vh - 2px);
  width: 100%;
  min-height: 80px;
  pointer-events: none;
  background: linear-gradient(270deg, var(--gradient-start), var(--gradient-end));
  clip-path: url(#modal-wave-svg-mask);
}

.modal-icon-wrapper {
  border-radius: 1000px;
  display: grid;
  align-items: center;
  width: 90px;
  height: 90px;
  padding: 10px;
  background-color: var(--main-light);
  margin: 20px auto;

  & svg {
    width: 80%;
    height: 80%;

    margin: 0 auto;
    .a {
      fill: var(--modal-header-icon-color);
    }

    .b {
      fill: var(--main-light);
      stroke: none;
    }

    .e {
      stroke: var(--modal-header-icon-color);
      stroke-miterlimit: 10;
      stroke-width: 1.85px;
    }
  }
}

#close-icon {
  width: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.1);
  }
}

@media (min-width: $bp-from-desktop) {
  #close-icon {
    position: absolute;
    right: 100px;
    top: -10px;

    .close-a {
      fill: var(--main-light);
    }

    .close-b {
      fill: url(#close-a);
    }
  }
}

.modal-content {
  padding: 15px;
  padding-bottom: 100px;
  overflow-y: auto;
  height: 80vh;
  line-height: 1.5;
  white-space: pre-wrap;

  @media (min-width: $bp-from-desktop) {
    height: 60vh;
    padding-bottom: 0;
    padding-top: 25px;
  }
}

.modal-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 150px 1fr;
  align-items: center;
  margin: 30px 0;

  @media (min-width: $bp-from-tablet) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 6fr;
  }

  & h3 {
    font-size: 1.2em;
    margin: 0;
  }

  & p {
    margin: 0;
  }
}

.modal-item-icon-wrapper {
  width: 25vw;
  height: 25vw;
  min-width: 60px;
  min-height: 60px;
  max-width: 100px;
  max-height: 100px;
  margin: 0 auto;

  @media (min-width: $bp-from-tablet) {
    width: 15vw;
    height: 15vw;
    min-width: 60px;
    min-height: 60px;
    max-width: 100px;
    max-height: 100px;
  }

  & img {
    width: 50%;
  }
}

.modal-item-description {
  @media (min-width: $bp-from-tablet) {
    margin-left: 15px;
  }
}