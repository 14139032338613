@import '~styles/breakpoints';

.slider-wrapper {
  background: var(--standard-gradient);
  height: 30vh;
  position: relative;
  overflow: hidden;
  width: 100%;

  &:hover .slider-arrow {
    opacity: 0.5;
  }

  @media (min-width: $bp-from-desktop) {
    margin-bottom: 20px;
    border-radius: 15px;
    width: 100%;
    height: 50%;
  }
}

.pictures-slider {
  position: relative;
  transition: transform 0.25s;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: max-content;
  height: 100%;
}

.slider-image {
  display: inline-block;
  height: 100%;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;

  @media (min-width: $bp-from-desktop) {
    background-size: 60%;
    background-position: left;
  }
}

.dots-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.slider-dot {
  width: 10px;
  height: 10px;
  border: 1.5px solid var(--secondary-light);
  display: inline-block;
  margin: 5px;
  border-radius: 1000px;
  cursor: pointer;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.2);

  &.active {
    background: var(--secondary-light);
  }
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 30px;
  top: 0;
  display: grid;
  align-items: center;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 0.25s;


  & svg {
    .z {
      stroke: var(--secondary-light);
    }

    cursor: pointer;
  }

  &:hover {
    opacity: 1 !important;
  }

  &.left {
    left: 5px;
    transform: scale(-0.5);
  }

  &.right {
    right: 5px;
  }
}