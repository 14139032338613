@import '~styles/breakpoints';

.loading-screen-wrapper {
  width: 100%;
  height: 100vh;
  background: linear-gradient(150deg, var(--gradient-start), var(--gradient-end));
  display: flex;
  align-items: center;
  justify-content: center;

  animation: fade-in 0.25s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loader {
  width: 30vw;
  height: 30vw;
  border-radius: 100vw;
  background: var(--main-light);
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 30vw;
    height: 30vw;
    border: 2px solid var(--main-light);
    border-radius: 100vw;
    animation: blink 2s infinite;
  }

  @media (min-width: $bp-from-desktop) {
    width: 10vw;
    height: 10vw;

    &::after {
      width: 10vw;
      height: 10vw;
    }
  }
}

@keyframes blink {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  99% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(0.9);
    opacity: 0;
  }
}

.loader-wave-wrapper {
  width: 90%;
  height: 90%;
  border-radius: 100vw;
  background: linear-gradient(150deg, var(--gradient-start), var(--gradient-end));
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.loader-wave {
  width: 100%;
  height: 100%;
  position: relative;
  top: 30%;
  background: var(--main-light);
  clip-path: url('#side-menu-horizontal-wave-mask');

  clipPath {
    height: 100%;
    transform: scaleY(2);
    position: absolute;
    bottom: 0;
  }
}